import React from "react"
import Card from "../card/Card"
const CardContainer = () => {
  return (
    <div
      id="footer#0"
      className="uk-section-default uk-section uk-padding-remove-top"
      uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-scale-up; delay: 200;"
    >
      <div className="uk-container uk-container-large">
        <div
          className="tm-grid-expand uk-child-width-1-1 uk-grid-margin uk-grid uk-grid-stack"
          uk-grid
        >
          <div className="uk-first-column">
            <div className="uk-margin uk-text-center">
              <div
                className="uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m uk-grid-column-small uk-grid-match uk-grid"
                uk-grid
              >
                <Card
                  {...{
                    link:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/informations/",
                    imgSrc:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/wp-content/uploads/2022/03/paiement-securise.svg",
                    text: "paiement sécurisé",
                  }}
                />
                <Card
                  {...{
                    link:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/informations/#livraison-rapide",
                    imgSrc:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/wp-content/uploads/2022/03/livraison-rapide.svg",
                    text: "Livraison rapide",
                  }}
                />
                <Card
                  {...{
                    link:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/informations/#produit-en-stock",
                    imgSrc:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/wp-content/uploads/2022/03/produits-en-stock.svg",
                    text: "Produits en stock",
                  }}
                />
                <Card
                  {...{
                    link:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/informations/#numero-un",
                    imgSrc:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/wp-content/uploads/2022/03/numero-1.svg",
                    text: "n° 1 dans son domaine",
                  }}
                />
                <Card
                  {...{
                    link:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/informations/#retour-sous-garantie",
                    imgSrc:
                      "https://" +
                      process.env.GATSBY_CURRENT_URL +
                      "/wp-content/uploads/2022/04/retour-sous-garantie.svg",
                    text: "Retour sous garantie",
                  }}
                />
                {/* <Card
                      {...{
                        link: "/informations/#retour-sous-garantie",
                        imgSrc:
                          "https://"+process.env.GATSBY_CURRENT_URL+"/wp-content/uploads/2022/03/a-vos-cote-depuis-plus-de-30-ans.svg",
                        text: "À vos côtés depuis + de 30ans",
                      }}
                    /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardContainer
