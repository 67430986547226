import React from "react"

const Copyright = () => {
  return (
    <div className="uk-section-secondary uk-section uk-section-xsmall">
      <div className="uk-grid-margin uk-container uk-container-large">
        <div className="tm-grid-expand uk-grid" uk-grid>
          <div className="uk-grid-item-match uk-flex-middle uk-width-4-5@m uk-first-column">
            <div className="uk-panel uk-width-1-1">
              <div className="uk-panel uk-text-small uk-text-muted uk-visible@m uk-margin uk-text-left@m uk-text-center">
                © <time dateTime="2022-03-18T10:07:02.919Z"> 2022 </time>
                setem.fr
              </div>
              <div className="uk-panel uk-text-small uk-text-muted uk-hidden@m uk-margin-medium uk-text-left@m uk-text-center">
                © <time dateTime="2022-03-18T10:07:02.929Z"> 2022 </time>
                setem.fr
              </div>
            </div>
          </div>
          <div className="uk-grid-item-match uk-flex-middle uk-width-1-5@m">
            <div className="uk-panel uk-width-1-1"> </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Copyright
