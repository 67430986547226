import React from "react"

const Location = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke="#000"
        strokeWidth="1.01"
        d="M10,0.5 C6.41,0.5 3.5,3.39 3.5,6.98 C3.5,11.83 10,19 10,19 C10,19 16.5,11.83 16.5,6.98 C16.5,3.39 13.59,0.5 10,0.5 L10,0.5 Z"
      />
      <circle fill="none" stroke="#000" cx={10} cy="6.8" r="2.3" />
    </svg>
  )
}

export default Location
