import React from "react"
import CardContainer from "./CardContainer"
import Copyright from "./Copyright"
import InformationLayout from "./information/InformationLayout"
import useWindowDimensions from "./useGetDimensions"
import Location from "../icons/Location"
import ReactHtmlParser from "react-html-parser"
import MailIcon from "../icons/MailIcon"
import PhoneIcon from "../icons/PhoneIcon"
import FaxIcon from "../icons/FaxIcon"

export const Footer = () => {
  return (
    <div>
      <CardContainer />
      <div
        id="footer#1"
        className="uk-section-default uk-section uk-section-small"
      >
        <div className="uk-container">
          <div
            className="tm-grid-expand uk-child-width-1-1 uk-grid-margin uk-grid uk-grid-stack"
            uk-grid
          >
            <div className="uk-first-column">
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer#2" className="uk-section-muted uk-section">
        <div className="uk-container">
          <div
            className="tm-grid-expand uk-child-width-1-1 uk-grid-margin uk-grid uk-grid-stack"
            uk-grid
          >
            <div className="uk-first-column">
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="uk-section-muted uk-section uk-padding-remove-top"
        uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-fade; delay: false;"
      >
        <div className="uk-container uk-container-large">
          <div
            className="tm-grid-expand uk-grid-column-small uk-grid-row-large uk-grid-divider uk-grid-margin-large uk-grid uk-grid-stack"
            uk-grid
          >
            <div className="uk-width-1-2@s uk-width-1-4@m uk-first-column">
              <div
                className="uk-margin uk-text-left uk-scrollspy-inview "
                uk-scrollspy-class="uk-animation-slide-top-small"
                style={{}}
              >
                <a
                  className="el-link"
                  href={`https://${process.env.GATSBY_CURRENT_URL}`}
                  uk-scroll
                >
                  <img
                    className="el-image small-logo"
                    alt="SETEM logo"
                    width={390}
                    height={55}
                    // data-src="/footer-setem-logo.png"
                    // data-srcset="/footer-setem-logo.png 300w, /footer-setem-logo.png 599w, /footer-setem-logo.png 600w"
                    // data-sizes="(min-width: 300px) 300px"
                    // data-width={390}
                    // data-height={55}
                    // uk-img
                    src="/setem_logo_3.png"
                    // // srcSet="/setem_logo_3.png 330w, /setem_logo_3.png 510w, /setem_logo_3.png 768w, /setem_logo_3.png 1017w, /setem_logo_3.png 1019w, /setem_logo_3.png 1020w"
                    // sizes="(min-width: 300px) 300px"
                    // // srcSet="/footer-setem-logo.png 300w, /footer-setem-logo.png 599w, /footer-setem-logo.png 600w"
                    // srcset="/footer-setem-logo.png 390w, /footer-setem-logo.png 768w, /footer-setem-logo.png 779w, /footer-setem-logo.png 780w"
                  />
                  {/* 
                  <img
                    className="el-image"
                    alt="SETEM logo"
                    data-src="/footer-setem-logo.png"
                    data-srcset="/footer-setem-logo.png 390w, /footer-setem-logo.png 768w, /footer-setem-logo.png 779w, /footer-setem-logo.png 780w"
                    data-sizes="(min-width: 390px) 390px"
                    data-width={390}
                    data-height={55}
                    uk-img
                    sizes="(min-width: 390px) 390px"
                    srcSet="/footer-setem-logo.png 390w, /footer-setem-logo.png 768w, /footer-setem-logo.png 779w, /footer-setem-logo.png 780w"
                    src="https://setem.fr/footer-setem-logo.png"
                  /> */}
                </a>
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-4@m">
              <div className="margin-mobile">
                <h3
                  className="uk-h6 uk-text-left uk-scrollspy-inview "
                  uk-scrollspy-class
                  style={{}}
                >
                  Informations
                </h3>
                <ul
                  className="uk-list uk-text-left uk-scrollspy-inview no-margin-bottom"
                  uk-scrollspy-class
                  style={{}}
                >
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        text: "Livraison",
                        link: `https://${process.env.GATSBY_CURRENT_URL}/livraison/`,
                      }}
                    />
                  </li>

                  <li className="el-item">
                    <InformationLayout
                      {...{
                        text: "Mentions légales",
                        link: `https://${process.env.GATSBY_CURRENT_URL}/mentions-legales/`,
                      }}
                    />
                  </li>
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        text: "Conditions générales de vente",
                        link: `https://${process.env.GATSBY_CURRENT_URL}/conditions-generales-de-vente/`,
                      }}
                    />
                  </li>
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        text: "Clause de confidentialité",
                        link: `https://${process.env.GATSBY_CURRENT_URL}/clause-de-confidentialite/`,
                      }}
                    />
                  </li>
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        text: "A propos de nous",
                        link: `https://${process.env.GATSBY_CURRENT_URL}`,
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-4@m">
              <div className="margin-mobile">
                <h3
                  className="uk-h6 uk-text-left uk-scrollspy-inview "
                  uk-scrollspy-class
                  style={{}}
                >
                  SIÈGE SOCIAL - ADMINISTRATIF
                </h3>
                <ul
                  className="uk-list uk-text-left uk-scrollspy-inview no-margin-bottom"
                  uk-scrollspy-class
                  style={{}}
                >
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        icon: Location,
                        text: ReactHtmlParser(` 885 chemin Saint Bernard <br />
                        06220 Vallauris <br/> France`),
                        link: "#",
                      }}
                    />
                  </li>
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        icon: MailIcon,
                        link: `mailTo:commercial@setem.fr`,
                        text: `commercial@setem.fr`,
                      }}
                    />
                  </li>
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        icon: PhoneIcon,
                        link: `#`,
                        text: `0 890 71 00 39`,
                      }}
                    />
                  </li>
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        icon: PhoneIcon,
                        link: `#`,
                        text: `04 93 64 35 19`,
                      }}
                    />
                  </li>
                  <li className="el-item">
                    <InformationLayout
                      {...{
                        icon: FaxIcon,
                        link: `#`,
                        text: `04 83 33 19 90`,
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-grid-item-match uk-width-1-2@s uk-width-1-4@m">
              <div className="margin-mobile">
                <div className="uk-panel uk-width-1-1">
                  <h3
                    className="uk-h6 uk-text-left uk-scrollspy-inview "
                    uk-scrollspy-class
                    style={{}}
                  >
                    MAGASIN ET LOGISTIQUE
                  </h3>
                  <ul
                    className="uk-list uk-list-primary uk-text-left uk-scrollspy-inview no-margin-bottom"
                    uk-scrollspy-class
                    style={{}}
                  >
                    <li className="el-item">
                      <InformationLayout
                        {...{
                          icon: Location,
                          text: ReactHtmlParser(`ZAC de la Malnoue<br>
                        85 avenue de l’Europe<br/>
                        77184 Emerainville<br/>France`),
                          link: "#",
                        }}
                      />
                    </li>
                    <li className="el-item">
                      <InformationLayout
                        {...{
                          icon: MailIcon,
                          link: `mailTo:commercial@setem.fr`,
                          text: `commercial@setem.fr`,
                        }}
                      />
                    </li>
                  </ul>
                  <div
                    className="uk-visible@m uk-position-absolute uk-width-1-1 uk-text-right uk-scrollspy-inview "
                    style={{ top: "330px" }}
                    uk-scrollspy-class
                  >
                    <a href="#" uk-totop uk-scroll className="uk-icon uk-totop">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={20}
                        viewBox="0 0 12 20"
                      >
                        <line
                          fill="none"
                          stroke="#000"
                          strokeWidth={2}
                          x1={6}
                          y1={18}
                          x2={6}
                          y2={1}
                        />
                        <polyline
                          fill="none"
                          stroke="#000"
                          strokeWidth={2}
                          points="0 8 6 1 13 8"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="uk-hidden@m tm-grid-expand uk-child-width-1-1 uk-grid-margin uk-grid uk-grid-stack"
            uk-grid
          >
            <div className="uk-grid-item-match uk-hidden@m uk-first-column">
              <div className="uk-panel uk-width-1-1">
                <div
                  className="uk-hidden@m uk-position-absolute uk-width-1-1 uk-text-center uk-scrollspy-inview "
                  style={{ top: "60px" }}
                  uk-scrollspy-class
                >
                  <a href="#" uk-totop uk-scroll className="uk-icon uk-totop">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={20}
                      viewBox="0 0 12 20"
                    >
                      <line
                        fill="none"
                        stroke="#000"
                        strokeWidth={2}
                        x1={6}
                        y1={18}
                        x2={6}
                        y2={1}
                      />
                      <polyline
                        fill="none"
                        stroke="#000"
                        strokeWidth={2}
                        points="0 8 6 1 13 8"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  )
}
/*
var wpcf7 = {"api":{"root":"https:\/\/setem-empty.cut.in.dmsc.solutions\/wp-json\/","namespace":"contact-form-7\/v1"}};
<script type='text/javascript' src='https://setem-empty.cut.in.dmsc.solutions/wp-content/plugins/contact-form-7/includes/js/index.js?ver=5.5.6' id='contact-form-7-js'/>
<script src='https://setem-empty.cut.in.dmsc.solutions/wp-includes/js/dist/vendor/regenerator-runtime.min.js?ver=0.13.9' />
<script
    type = 'text/javascript'
    src = 'https://setem-empty.cut.in.dmsc.solutions/wp-includes/js/dist/vendor/wp-polyfill.min.js?ver=3.15.0'
    id = 'wp-polyfill-js' >*/
