import PropTypes from "prop-types"
import React from "react"

const InformationLayout = ({ icon, text, link }) => {
  const Icon = icon
  return (
    <div
      className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-grid"
      uk-grid
    >
      {icon ? (
        <div className="uk-width-auto uk-first-column">
          <a href={link} uk-scroll>
            <span className="el-image uk-icon" uk-icon="icon: location;">
              <Icon />
            </span>
          </a>
        </div>
      ) : null}
      <div>
        <div className="el-content uk-panel">
          <a
            href={link}
            uk-scroll
            className="el-link uk-link-text uk-margin-remove-last-child"
          >
            {text}
          </a>
        </div>
      </div>
    </div>
  )
}

InformationLayout.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default InformationLayout
