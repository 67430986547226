import React from "react"
// import "./Header.styles.css"
const MobileModal = (props) => {
  const { onClose, open } = props
  return (
    <>
      {open ? (
        <div
          id="tm-mobile"
          className="uk-modal-full uk-modal uk-open"
          uk-modal
          style={{ display: "block" }}
          tabIndex={-1}
        >
          <div className="uk-modal-dialog uk-modal-body uk-text-center uk-flex uk-height-viewport">
            <button
              className="uk-modal-close-full uk-icon uk-close"
              type="button"
              uk-close
              onClick={() => {
                onClose(false)
              }}
            >
              <svg
                width={14}
                height={14}
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1={1}
                  y1={1}
                  x2={13}
                  y2={13}
                />
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1={13}
                  y1={1}
                  x2={1}
                  y2={13}
                />
              </svg>
            </button>
            <div className="uk-margin-auto-vertical uk-width-1-1">
              <div className="uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid>
                <div className="uk-first-column">
                  <div className="uk-panel">
                    <ul className="uk-nav uk-nav-primary uk-nav-center">
                      <li className="menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href={`https://brand.${process.env.GATSBY_CURRENT_URL}/les-marques`}
                          className={"navigation-text"}
                        >
                          Les marques
                        </a>
                      </li>
                      {/* Temporarily remove those menu */}
                      {/* <li className="menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="https://accessoires.setem.fr"
                        className={"navigation-text"}>
                          {" "}
                          Les accessoires
                        </a>
                      </li>*/}
                      <li className="menu-item menu-item-type-post_type menu-item-object-page">
                        <a
                          href={`https://${process.env.GATSBY_CURRENT_URL}/telechargements/`}
                          className={"navigation-text"}
                        >
                          Téléchargements
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href={`https://${process.env.GATSBY_CURRENT_URL}/wp-content/uploads/2022/06/NEW-CATALOGUE-270622.pdf`}
                          className={"navigation-text"}
                        >
                          Catalogue
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href={`https://contact.${process.env.GATSBY_CURRENT_URL}/contact`}
                          className={"navigation-text"}
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="uk-grid-margin uk-first-column">
                  <div className="uk-panel widget widget_search" id="search-3">
                    <form
                      id="search-c4ce"
                      action={`https://${process.env.GATSBY_CURRENT_URL}`}
                      method="get"
                      role="search"
                      className="uk-search uk-search-default uk-width-1-1"
                    >
                      <span uk-search-icon className="uk-icon uk-search-icon">
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            fill="none"
                            stroke="#000"
                            strokeWidth="1.1"
                            cx={9}
                            cy={9}
                            r={7}
                          />
                          <path
                            fill="none"
                            stroke="#000"
                            strokeWidth="1.1"
                            d="M14,14 L18,18 L14,14 Z"
                          />
                        </svg>
                      </span>
                      <input
                        name="s"
                        placeholder="Recherche"
                        required
                        type="search"
                        className="uk-search-input"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default MobileModal
