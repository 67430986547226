import React, { useState } from "react"
import MobileModal from "./MobileModal"

const MobileHeader = () => {
  const [open, setOpen] = useState(false)

  const toggleOpen = (open) => {
    setOpen(open)
  }

  return (
    <div>
      <div className="tm-header-mobile uk-hidden@m">
        <div className="uk-navbar-container">
          <nav uk navbar="container: .tm-header-mobile" className="uk-navbar">
            <div className="uk-navbar-left">
              <a
                className="uk-navbar-toggle"
                href="#"
                uk-toggle
                aria-expanded={false}
                onClick={() => toggleOpen(true)}
              >
                <div
                  uk-navbar-toggle-icon
                  className="uk-icon uk-navbar-toggle-icon"
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect y="9" width="20" height="2"></rect>
                    <rect y="3" width="20" height="2"></rect>
                    <rect y="15" width="20" height="2"></rect>
                  </svg>
                </div>
                <div className="uk-navbar-center">
                  <a
                    href={`https://${process.env.GATSBY_CURRENT_URL}`}
                    className="uk-navbar-item uk-logo"
                  >
                    <img
                      alt="SETEM"
                      sizes="(min-width: 250px) 250px"
                      data-width="250"
                      data-height="36"
                      src="/logo_setem_v2.png"
                      srcSet="/logo_setem_v2.png 250W, /logo_setem_v2.png 498w, /logo_setem_v2.png 499w, /logo_setem_v2.png 500w"
                    />
                  </a>
                </div>
              </a>
              <MobileModal {...{ onClose: toggleOpen, open }} />
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default MobileHeader
