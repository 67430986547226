import React from "react"
import PropTypes from "prop-types"
import "./card.css"
const Card = (props) => {
  const { link, imgSrc, text } = props
  return (
    <div className="uk-grid-margin uk-first-column">
      <div
        className="el-item uk-card uk-card-default uk-card-small uk-card-hover uk-card-body uk-margin-remove-first-child uk-link-toggle uk-display-block uk-scrollspy-inview  "
        uk-scrollspy-class
        style={{}}
      >
        <a
          className="el-item uk-panel uk-margin-remove-first-child uk-link-toggle uk-display-block"
          href={link}
        >
          <img
            width={80}
            className="el-image"
            alt="paiement sécurisé SETEM"
            data-src="/wp-content/uploads/2022/03/paiement-securise.svg"
            uk-img
            src={imgSrc}
          />
          <div className="el-meta uk-h6 uk-margin-remove-bottom uk-margin-remove-top card-text">
            {text}
          </div>
        </a>
      </div>
    </div>
  )
}

Card.propTypes = {
  link: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Card
